import { ThemeUIStyleObject } from 'theme-ui';
import { SLIDER_MOVE_DURATION } from './constants';

const BASE_CONTAINER_STYLES: ThemeUIStyleObject = {
  maxWidth: '472px',
  width: '100%',
  marginBlock: '16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
};

const ICON_STYLES: ThemeUIStyleObject = {
  display: ['none', null, 'block'],
  width: '40px',
  height: '40px',
  marginRight: '16px',
};

const MAIN_CONTAINER_STYLES: ThemeUIStyleObject = {
  flex: 1,
};
const TITLE_STYLES: ThemeUIStyleObject = {
  color: '#fff',
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '18px',
  letterSpacing: '0.4px',
  marginBottom: '12px',
};
const SLIDER_CONTAINER_STYLES: ThemeUIStyleObject = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  '.rc-slider': {
    height: '28px',
    borderRadius: '0px',
    padding: 0,
  },
  '.rc-slider-rail': {
    height: '100%',
    borderRadius: '0px',
    backgroundColor: '#fff',
  },
  '.rc-slider-track': {
    height: '100%',
    borderRadius: '0px',
    background: 'linear-gradient(91.25deg, #00BAE9 0%, #70BF54 100%)',
  },
  '.rc-slider-handle': {
    height: '32px',
    width: '32px',
    border: '0',
    marginTop: 0,
    background: '#C9D036',
    opacity: 1,
    top: '-2px',
  },
  '.rc-slider-disabled .rc-slider-handle': {
    background: '#C9D036',
  },
};
const SLIDER_LEFT_STUB_STYLES: ThemeUIStyleObject = {
  height: '28px',
  width: '16px',
  backgroundColor: '#00BAE9',
  borderTopLeftRadius: '10rem',
  borderBottomLeftRadius: '10rem',
};
const SLIDER_RIGHT_STUB_STYLES: ThemeUIStyleObject = {
  height: '28px',
  width: '16px',
  backgroundColor: '#fff',
  borderTopRightRadius: '10rem',
  borderBottomRightRadius: '10rem',
};
export const getStyles = ({ isPredictionPerformed }: { isPredictionPerformed: boolean }) => ({
  container: BASE_CONTAINER_STYLES,
  icon: ICON_STYLES,
  mainContainer: MAIN_CONTAINER_STYLES,
  title: TITLE_STYLES,
  sliderContainer: {
    ...SLIDER_CONTAINER_STYLES,
    '.rc-slider-track': {
      ...(SLIDER_CONTAINER_STYLES['.rc-slider-track'] as ThemeUIStyleObject),
      transition: isPredictionPerformed ? `all ${SLIDER_MOVE_DURATION}s linear` : 'none',
    },
    '.rc-slider-handle': {
      ...(SLIDER_CONTAINER_STYLES['.rc-slider-handle'] as ThemeUIStyleObject),
      transition: isPredictionPerformed ? `all ${SLIDER_MOVE_DURATION}s linear` : 'none',
    },
  },
  sliderLeftStub: SLIDER_LEFT_STUB_STYLES,
  sliderRightStub: SLIDER_RIGHT_STUB_STYLES,
});
