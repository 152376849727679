export const formatValue = (value: number, format: 'number' | 'duration' | 'percentage') => {
  switch (format) {
    case 'number': {
      return value;
    }
    case 'duration': {
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      const hourString = hours > 0 ? `${hours}h ` : '';
      const minString = minutes > 0 ? `${minutes}m` : '';
      return `${hourString}${minString}`;
    }
    case 'percentage': {
      return `${value}%`;
    }
    default: {
      return value;
    }
  }
};
