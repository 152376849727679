import React from 'react';
import { SLIDER_MOVE_DURATION } from './constants';
import { formatValue } from './utils';
import { Box } from 'theme-ui';

const renderSliderHandleFactory =
  (slider, isPredictionPerformed) => (node, props) => {
    const interpolatedPercentage =
      ((props.value - slider.rangeMinimumValue) /
        (slider.rangeMaximumValue - slider.rangeMinimumValue)) *
      100;
    return (
      <React.Fragment>
        {node}
        <Box
          sx={{
            position: 'absolute',
            left: `${interpolatedPercentage}%`,
            top: '50%',
            transform: 'translate(20px, -50%)',
            fontWeight: '700',
            fontSize: '12px',
            lineHeight: '24px',
            letterSpacing: '0.4px',
            opacity: isPredictionPerformed ? 1 : 0,
            transition: `opacity 100ms ${SLIDER_MOVE_DURATION}s`,
          }}
        >
          {formatValue(props.value, slider.format)}
        </Box>
      </React.Fragment>
    );
  };

export default renderSliderHandleFactory;
