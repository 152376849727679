import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box } from 'theme-ui';
import ImageElement from '@sprinklr/shared-lib/components/listView/ImageElement';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { Slider as SliderProps } from '../../types';
import { getStyles } from './styles';
import renderSliderHandleFactory from './renderSliderHandleFactory';

const BenchmarkSlider = ({ slider }: { slider: SliderProps }) => {
  const [currentValue, setCurrentValue] = useState(slider.initialValue);
  const [isPredictionPerformed, setIsPredictionPerformed] = useState(false);

  // Set final slider value after prediction is performed
  useEffect(() => {
    if (!isPredictionPerformed) return;
    if (currentValue === slider.value) return;
    setCurrentValue(slider.value);
  }, [currentValue, isPredictionPerformed]);

  const styles = useMemo(
    () =>
      getStyles({
        isPredictionPerformed,
      }),
    [isPredictionPerformed],
  );

  const handleChange = useCallback(value => {
    setCurrentValue(value as number);
  }, []);

  const handleAfterChange = useCallback(() => {
    setIsPredictionPerformed(true);
  }, []);

  const renderSliderHandle = useMemo(
    () => renderSliderHandleFactory(slider, isPredictionPerformed),
    [slider, isPredictionPerformed],
  );

  return (
    <Box sx={styles.container}>
      <ImageElement srcData={slider.svg} imageSx={styles.icon} />
      <Box sx={styles.mainContainer}>
        <Box sx={styles.title}>{slider.title}</Box>
        <Box sx={styles.sliderContainer}>
          <Box sx={styles.sliderLeftStub} />
          <Slider
            disabled={isPredictionPerformed}
            min={slider.rangeMinimumValue}
            max={slider.rangeMaximumValue}
            value={currentValue}
            onChange={handleChange}
            onAfterChange={handleAfterChange}
            handleRender={renderSliderHandle}
          />
          <Box sx={styles.sliderRightStub} />
        </Box>
      </Box>
    </Box>
  );
};

export default BenchmarkSlider;
