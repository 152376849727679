/** @jsx jsx */
import { CallToAction } from '@sprinklr/shared-lib';
import { Box, jsx } from 'theme-ui';

import { Props } from './types';
import BenchmarkSlider from './components/BenchmarkSlider';

const CxWiseSliderTemplate = (props: Props) => {
  const { beforeText, ctAs, sliders } = props.list[0];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBlock: ['28px 76px', null, '44px 64px'],
      }}
    >
      <Box
        sx={{
          fontWeight: 500,
          fontSize: ['24px', null, '32px'],
          lineHeight: ['30px', null, '40px'],
          color: '#FFFFFF',
          maxWidth: '692px',
          textAlign: 'center',
          paddingInline: '50px',
        }}
      >
        {beforeText}
      </Box>
      <Box
        sx={{
          marginBlock: ['36px', null, '36px 64px'],
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '44px',
          paddingInline: '36px 48px',
        }}
      >
        {sliders.map(slider => (
          <BenchmarkSlider key={slider.title} slider={slider} />
        ))}
      </Box>

      <CallToAction {...ctAs[0]} />
    </Box>
  );
};

export default CxWiseSliderTemplate;
